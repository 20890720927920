// used for Chicago Order Forms Only
import { cloneDeep } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Datetime from 'react-datetime';

import { checkQuantityValidity } from '../../../functions';
import { convertToCamelCase, convertToSnakeCase } from '../../../helpers';
import SelectField from '../../basic/SelectField';
import ErrorMessage from '../order-form/ErrorMessage';
import { getIngredientOptions } from '../../../utils';
import { Table, Box, Input, IconButton, Flex } from '@chakra-ui/react';
import { ReactComponent as TrashOutlineIconSVG } from '../../../assets/trash-outline-icon.svg';
import ProductionDetails from '../order-form/ProductionDetails/ProductionDetails';
import { connect } from 'react-redux';
import { findAllEntitiesWithoutFilterInState } from '../../../slices/masterData/entityManagerSlice';

const thTdProps = {
  paddingY: '12.5px',
  paddingX: '5px',
};

const OrderResultsTableTh = ({ children, ...rest }) => {
  return (
    <Table.ColumnHeader bgColor="neutral.9" borderBottomWidth="1px" borderColor="neutral.8" {...thTdProps} {...rest}>
      {children}
    </Table.ColumnHeader>
  );
};

OrderResultsTableTh.propTypes = {
  children: PropTypes.node.isRequired,
};

const OrderResultsTableTd = ({ children, ...rest }) => {
  return (
    <Table.Cell borderWidth="0px" {...thTdProps} {...rest}>
      {children}
    </Table.Cell>
  );
};

OrderResultsTableTd.propTypes = {
  children: PropTypes.node.isRequired,
};

class OrderResults extends Component {
  static propTypes = {
    grinders: PropTypes.arrayOf(PropTypes.object),
    all_grinders: PropTypes.arrayOf(PropTypes.object),
    input_products: PropTypes.arrayOf(PropTypes.object),
    all_input_products: PropTypes.arrayOf(PropTypes.object),
    load_sizes: PropTypes.arrayOf(PropTypes.object).isRequired,
    onOrderLinesChange: PropTypes.func,
    order: PropTypes.shape({
      lines: PropTypes.arrayOf(PropTypes.object),
      packer_plant_id: PropTypes.string,
      grinder_uid: PropTypes.string,
      buy_unit_of_measure_id: PropTypes.number,
      isChilled: PropTypes.bool,
      buyUnitOfMeasure: PropTypes.instanceOf(Object),
    }),
    packer_plants: PropTypes.arrayOf(PropTypes.object),
    all_packer_plants: PropTypes.arrayOf(PropTypes.object),
    user: PropTypes.shape({
      organisation: PropTypes.shape({
        org_type: PropTypes.string,
      }),
    }),
    lines: PropTypes.instanceOf(Object).isRequired,
    submitProductionDetails: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.handleLineSelectValue = this.handleLineSelectValue.bind(this);
    this.removeAllWarnings = this.removeAllWarnings.bind(this);

    this.state = {
      validationErrors: {
        buy_quantity: false,
      },
      editable: false,
      orderChanged: false,
      currentLines: this.props.order.lines ? convertToSnakeCase(this.props.order.lines) : [],
      dateWarnings: [],
    };
  }

  removeAllWarnings() {
    this.setState({ dateWarnings: [] });
  }

  componentDidMount() {
    window.addEventListener('keypress', this.removeAllWarnings, false);
  }

  componentWillUnmount() {
    window.removeEventListener('keypress', this.removeAllWarnings);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      currentLines: convertToSnakeCase(nextProps.order.lines),
      editable: nextProps.editing,
    });
  }

  handleLineQuantityChange = (index, e) => {
    const { currentLines } = this.state;
    const newLines = cloneDeep(currentLines);
    newLines[index].buy_quantity = e.target.value;
    this.setState({ currentLines: newLines });
  };

  handleLineSelectValue = (index, field, e) => {
    /* TODO
      - Filter newLines to get only active lines
      - No buy quantity validation for 0
      - Highlight the buy quantity column which has issue
      - If it is not meant for decimals don’t allow .00 as well
      - Disable update button when there is an issue 
    */
    const { currentLines, dateWarnings } = this.state;
    let newLines = cloneDeep(currentLines);
    if (field === 'expected_production_date' && e._isAMomentObject) {
      if (e.isBefore(moment().subtract(14, 'days'))) {
        const currentWarnings = cloneDeep(dateWarnings);
        currentWarnings.push(index);
        this.setState({ dateWarnings: currentWarnings });
      }
    }
    if (e.target) {
      newLines[index][field] = e.target.value;
    } else if (e.value) {
      newLines[index][field] = e.value;
    } else if (e._isAMomentObject) {
      newLines[index][field] = e.format('YYYY-MM-DD');
    } else if (e !== '') {
      newLines[index][field] = e;
    } else {
      newLines[index][field] = null;
    }
    // validation
    const { all_packer_plants, all_grinders, order, load_sizes: loadSizes } = this.props;
    const originCountry = all_packer_plants.find(pp => pp.id === order.packer_plant_id)?.origin_country;
    const destinationCountry = all_grinders.find(grinder => grinder.uid === order.grinder_uid)?.destination_country;

    const buyQuantityError = checkQuantityValidity({
      fieldName: 'buy_quantity',
      originCountry,
      destinationCountry,
      lines: newLines.filter(line => line.active),
      loadSizes: convertToCamelCase(loadSizes),
      buyUnitOfMeasureId: this.props.order.buy_unit_of_measure_id,
    });

    if (buyQuantityError) {
      this.setState({ validationErrors: { buy_quantity: true } });
      return;
    }
    this.setState({ validationErrors: { buy_quantity: false } });

    /*  - parsing string quantity to int
        - adding line_numbers to the newly added lines
          by assuming that all existing order lines have line_number
        - set sell and buy buy_quantity same as buy_quantity for chicago order */
    newLines = newLines.map(n => {
      const lineWithDetails = {
        ...n,
        ...('buy_quantity' in n && { buy_quantity: Number(n.buy_quantity), sell_quantity: Number(n.buy_quantity) }),
        ...('buy_quantity' in n && 'line_number' in n && { line_number: newLines.length }),
      };

      return lineWithDetails;
    });

    this.setState({ currentLines: newLines });
    // sending updated data to parent as function
    this.props.onOrderLinesChange(newLines);
  };

  removeWarning(index) {
    const { dateWarnings } = this.state;
    const currentWarnings = JSON.parse(JSON.stringify(dateWarnings));
    currentWarnings.splice(currentWarnings.indexOf(index));
    this.setState({ dateWarnings: currentWarnings });
  }

  handleProductionDetailsChange = (lineIdx, lineId, productionDetails, toDeleteProductionDetailsIds) => {
    const { submitProductionDetails } = this.props;
    submitProductionDetails(lineIdx, lineId, productionDetails, toDeleteProductionDetailsIds);
  };

  render() {
    const { currentLines, editable, validationErrors } = this.state;
    const {
      input_products: inputProducts,
      all_input_products: allInputProducts,
      user,
      order,
      lines,
      units_of_measure: unitsOfMeasure,
      current_order: currentOrder,
      all_packer_plants: allPackerPlants,
    } = this.props;

    const orderPackerPlant = allPackerPlants.find(pp => pp.id === currentOrder.packer_plant_id);
    const packerPlantOriginCountry = orderPackerPlant ? orderPackerPlant.origin_country : '';
    const products = editable ? inputProducts : allInputProducts;
    const ingredientOptions = getIngredientOptions(
      allInputProducts,
      [
        {
          filterKey: 'COUNTRY',
          filterArgs:
            packerPlantOriginCountry === 'Canada'
              ? ['USA', 'Canada', 'Australia', 'New Zealand']
              : [packerPlantOriginCountry],
        },
      ],
      !editable
    );
    const fabricationDateInputProps = {
      placeholder: 'Fabrication',
      className: 'form-control m-input',
    };

    return (
      // TODO, use boostrap grid, issue: row doesnt cover whole modal when used
      <>
        <Box overflowX="visible" overflowY="visible">
          <Table.Root variant="unstyled" size="lg">
            <Table.Header>
              <Table.Row>
                <OrderResultsTableTh
                  backgroundColor="card.default"
                  borderBottomWidth="0px"
                  padding="0px"
                  width="40px"
                />
                <OrderResultsTableTh>Product</OrderResultsTableTh>
                <OrderResultsTableTh>Expected Production Date</OrderResultsTableTh>
                <OrderResultsTableTh>Quantity</OrderResultsTableTh>
                {user.organisation.org_type.indexOf('grinder') === -1 && (
                  <OrderResultsTableTh colSpan={2}>Buy Price</OrderResultsTableTh>
                )}
                {editable &&
                  user.organisation.org_type.indexOf('grinder') === -1 &&
                  user.organisation.org_type.indexOf('packer') === -1 && (
                    <OrderResultsTableTh>Actions</OrderResultsTableTh>
                  )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {currentLines.map((line, index) => {
                // TODO - figure out why this frag is here
                // let thisInputProduct = false;
                // inputProducts.forEach(ip => {
                //   if (ip.uid === line.input_product_uid) {
                //     thisInputProduct = ip;
                //   }
                // });
                const linePricePerUnit = line.price_per_unit ? parseFloat(line.price_per_unit).toFixed(4) : '';
                const lineSellPricePerUnit = line.sell_price_per_unit ? `$ ${line.sell_price_per_unit.toFixed(4)}` : '';
                return (
                  <>
                    <Table.Row
                      id="deletedLine"
                      key={`table-${order.id}-${index}`}
                      style={line.active || !line.id ? {} : { background: 'rgba(255,0,0,0.2)' }}
                    >
                      <OrderResultsTableTd>
                        <Flex
                          borderRightWidth="1px"
                          color="accent.one.default"
                          borderColor="black"
                          height="36px"
                          fontWeight="medium"
                          alignItems="center"
                          width="fit-content"
                          paddingRight="4px"
                        >
                          {index + 1}
                        </Flex>
                      </OrderResultsTableTd>
                      <OrderResultsTableTd style={{ width: '336px' }} paddingLeft="0px">
                        <SelectField
                          name="select-input-product"
                          defaultValue={line.input_product_uid || ''}
                          options={ingredientOptions}
                          placeholder="Select a product"
                          onChange={e => this.handleLineSelectValue(index, 'input_product_uid', e)}
                          disabled={!(editable && line.active && user.organisation.org_type.indexOf('packer') === -1)}
                        />
                      </OrderResultsTableTd>
                      <OrderResultsTableTd>
                        <Box>
                          {editable &&
                            line.active &&
                            user.organisation.org_type.indexOf('packer') === -1 &&
                            this.state.dateWarnings.indexOf(index) > -1 && (
                              <div
                                onClick={this.removeWarning.bind(this, index)}
                                style={{ textTransform: 'none' }}
                                className="fab-date-alert alert alert-info"
                              >
                                <i className="flaticon-circle">&nbsp;</i>
                                The date you have selected is greater than 14 days in the past. Please ensure you have
                                selected the correct date before continuing
                              </div>
                            )}

                          <Datetime
                            id="expected_production_date"
                            inputProps={{ ...fabricationDateInputProps, disabled: !(editable && line.active) }}
                            closeMenuOnSelect
                            value={line.expected_production_date}
                            onChange={e => this.handleLineSelectValue(index, 'expected_production_date', e)}
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                          />
                        </Box>
                      </OrderResultsTableTd>
                      <OrderResultsTableTd>
                        <Input
                          backgroundColor="white"
                          id="buy_quantity"
                          type="number"
                          className="form-control "
                          placeholder="Quantity"
                          onBlur={e => this.handleLineSelectValue(index, 'buy_quantity', e)}
                          onChange={e => this.handleLineQuantityChange(index, e)}
                          value={line.buy_quantity}
                          disabled={
                            !(editable && line.active && this.props.user.organisation.org_type.indexOf('packer') === -1)
                          }
                          onWheelCapture={e => {
                            e.target.blur();
                          }}
                        />
                      </OrderResultsTableTd>
                      <OrderResultsTableTd>
                        <Input
                          backgroundColor="white"
                          id="price_per_unit"
                          type="number"
                          className="form-control "
                          placeholder="Price Per Unit"
                          onChange={e => this.handleLineSelectValue(index, 'price_per_unit', e)}
                          value={editable && line.active ? line.price_per_unit : linePricePerUnit}
                          disabled={
                            !(
                              editable &&
                              line.active &&
                              user.organisation.org_type.indexOf('packer') === -1 &&
                              user.organisation.org_type.indexOf('grinder') === -1
                            )
                          }
                          onWheelCapture={e => {
                            e.target.blur();
                          }}
                        />
                      </OrderResultsTableTd>
                      <OrderResultsTableTd>
                        <Input
                          backgroundColor="white"
                          id="sell_price_per_unit"
                          type="number"
                          className="form-control "
                          placeholder="Sell Price Per Unit"
                          disabled
                          value={lineSellPricePerUnit}
                          onWheelCapture={e => {
                            e.target.blur();
                          }}
                        />
                      </OrderResultsTableTd>
                      <OrderResultsTableTd textAlign="center">
                        {editable &&
                          editable &&
                          user.organisation.org_type.indexOf('grinder') === -1 &&
                          user.organisation.org_type.indexOf('packer') === -1 &&
                          line.active && (
                            <IconButton
                              type="button"
                              id="deletedLine"
                              onClick={() => this.props.deleteLine(line, index)}
                            >
                              <TrashOutlineIconSVG />
                            </IconButton>
                          )}
                      </OrderResultsTableTd>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell />
                      <Table.Cell colSpan={6}>
                        <ProductionDetails
                          {...{
                            unitsOfMeasure,
                            line: { ...convertToCamelCase(line), idx: line.line_number },
                            isInEditMode: editable && line.active,
                            index,
                            onChange: this.handleProductionDetailsChange,
                            isChicago: true,
                            isFrozenProduct: !order.isChilled,
                            orderBuyUOM: order.buyUnitOfMeasure,
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </>
                );
              })}
            </Table.Body>
          </Table.Root>
        </Box>
        {editable &&
          user.organisation.org_type.indexOf('grinder') === -1 &&
          user.organisation.org_type.indexOf('packer') === -1 && (
            <IconButton
              type="button"
              colorScheme="actionSecondary"
              marginLeft="auto !important"
              width="fit-content"
              onClick={this.props.addLine}
            >
              <i className="flaticon-add" />
            </IconButton>
          )}
        {validationErrors.buy_quantity && (
          <ErrorMessage form={order} buyQuantityError={validationErrors.buy_quantity} {...this.props} />
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  const result = {
    ...findAllEntitiesWithoutFilterInState(state, {
      all_packer_plants: 'packer_plant',
      all_input_products: 'input_product',
      all_grinders: 'grinder',
    }),
  };
  const cameled = convertToCamelCase(result);
  return cameled;
};

export default connect(mapStateToProps)(OrderResults);
