import React, { Component } from 'react';
import { FaCalendar, FaChevronDown, FaSearch } from 'react-icons/fa';
import { CiExport } from 'react-icons/ci';
import {
  Box,
  Button,
  CheckboxGroup,
  Flex,
  HStack,
  Heading,
  Image,
  Input,
  Stack,
  Table,
  Text,
  VStack,
  createListCollection,
} from '@chakra-ui/react';
import get from 'lodash/get';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import { connect } from 'react-redux';
import Constants from '../../Constants';
import { fetchConfigs } from '../../actions/actions_config';
import { clearOrders, fetchOrderHistory } from '../../actions/actions_orders';
import { fetchPackageWeights } from '../../actions/actions_package_weights';
import { fetchSpecifications } from '../../actions/actions_specifications';
import { fetchBuyerOrgUsers } from '../../actions/actions_users';
import { ReactComponent as ShareOutlineSVG } from '../../assets/share-outline.svg';
import RightAngleIcon from '../core/RightAngleIcon';
import OrderTable from '../../containers/orders/OrderTable';
import GridOrderTable from '../../containers/orders/GridOrderTable';
import { commify, wrapDispatch } from '../../functions';
import { constructBuyerOptions } from '../../helpers';
import { buyersSelector } from '../../reducers/reducer_users';
import { generateAndDownloadExcel } from '../../services/excel-export';
import { fetchEntitiesData } from '../../slices/masterData/entityManagerSlice';
import { fetchOrderStatusMap, selectOrderStatusMap } from '../../slices/order-status-map/orderStatusMapSlice';
import { getInternalPoLabel } from '../../utils';
import SelectField from '../basic/SelectField';
import { PaginationBar } from '../core/PaginationComponents';
import './OrderHistory.scss';
import { convertCartonsToUnitOfMeasure } from './order-form/InternationalOrderLine/helpers';
import { InputGroup } from '../ui/input-group';
import { Field } from '@/components/ui/field';
import { MenuContent, MenuRoot, MenuTrigger } from '../ui/menu';
import { SelectRoot, SelectTrigger, SelectValueText, SelectContent, SelectItem } from '@/components/ui/select';
import { Checkbox } from '@/components/ui/checkbox';

const getQueryParam = (param, defaultValue = null) => new URLSearchParams(location.search).get(param) || defaultValue;

const CLEAN_FILTERS = {
  user_id: [],
  status: [],
  ordered_start_date: '',
  ordered_end_date: '',
  delivery_start_date: '',
  grinder_uids: null,
  grinder_po_number: getQueryParam('grinder_po_number', ''),
  delivery_end_date: '',
  packer_plant_ids: null,
  packer_ids: null,
  exclude_delisted_packer_plants: false,
  exclude_delisted_packers: false,
  partial_search: false,
  noEtd: false,
  buyer_id: null,
  po_lookup_field: window.location.href.includes('cfc')
    ? getQueryParam('po_lookup_field', 'internal_po_number')
    : getQueryParam('po_lookup_field', 'grinder_po_number'),
  internal_po_number: getQueryParam('internal_po_number', ''),
  packer_so_number: getQueryParam('packer_so_number', ''),
  booking_reference_number: '',
  missing_doc_type: null,
  shipmentPortMark: '',
  tags: [],
  specifications: [],
  etd_start_date: '',
  etd_end_date: '',
  eta_start_date: '',
  eta_end_date: '',
  orderShipmentTypes: [],
  product_types: '',
  isColdStoreRequired: null,
  end_user_id: null,
  container_number: '',
  packer_invoice_number: getQueryParam('packer_invoice_number', ''),
  bol_number: '',
  us_customs_entry_number: '',
  schema_type: 'order_history',
  sortColumns: 'order_date_DESC',
  page: 1,
  limit: 20,
};

const filtersSchema = {
  bol_number: {
    label: 'BOL #',
    type: 'text',
  },
  booking_reference_number: {
    label: 'Booking Reference #',
    type: 'text',
  },
  container_number: {
    label: 'Container Number',
    type: 'text',
  },
  shipmentPortMark: {
    label: 'Shipment Port Mark',
    type: 'text',
  },
  us_customs_entry_number: {
    label: 'US Customs Entry #',
    type: 'text',
  },
  missing_doc_type: {
    label: 'Missing Document Type',
    type: 'select',
  },
  buyer_id: {
    label: 'Buyer',
    type: 'text',
  },
  delivery_end_date: {
    label: 'Delivery Date To',
    type: 'text',
  },
  delivery_start_date: {
    label: 'Delivery Date From',
    type: 'select',
  },
  end_user_id: {
    label: 'End User',
    type: 'select',
  },
  eta_end_date: {
    label: 'ETA To',
    type: 'select',
  },
  eta_start_date: {
    label: 'ETA From',
    type: 'select',
  },
  etd_end_date: {
    label: 'ETD To',
    type: 'select',
  },
  etd_start_date: {
    label: 'ETD From',
    type: 'select',
  },
  grinder_po_number: {
    label: 'Grinder PO #',
    type: 'select',
  },
  internal_po_number: {
    label: 'Internal PO #',
    type: 'select',
  },
  ordered_end_date: {
    label: 'Date Created To',
    type: 'select',
  },
  ordered_start_date: {
    label: 'Date Created From',
    type: 'select',
  },
  packer_ids: {
    label: 'Packer',
    type: 'select',
  },
  packer_plant_ids: {
    label: 'Establishment',
    type: 'select',
  },
  grinder_uids: {
    label: 'Grinder',
    type: 'select',
  },
  isColdStoreRequired: {
    label: 'Cold Store Required',
    type: 'select',
  },
  packer_so_number: {
    label: 'Packer SO #',
    type: 'select',
  },
  packer_invoice_number: {
    label: 'Packer Invoice #',
    type: 'select',
  },
  specifications: {
    label: 'Specification',
    type: 'select',
  },
  status: {
    label: 'Status',
    type: 'select',
  },
  tags: {
    label: 'Tags',
    type: 'select',
  },
  orderShipmentTypes: {
    label: 'Order Shipment Type',
    type: 'select',
  },
  product_types: {
    label: 'Product Type',
    type: 'select',
  },
};

const CheckboxGroupContainer = ({ children, ...rest }) => {
  return (
    <Flex gap="24px" {...rest.wrapStyle}>
      <CheckboxGroup colorScheme="actionSecondary" {...rest.checkBoxGroupStyle}>
        {children}
      </CheckboxGroup>
    </Flex>
  );
};

CheckboxGroupContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const OrderHistorySelectInternalSelect = props => {
  return (
    <SelectField
      styles={{
        control: styles => ({
          ...styles,
          minHeight: '40px',
        }),
        menu: styles => ({
          ...styles,
          zIndex: '10',
        }),
      }}
      isClearable
      {...props}
    />
  );
};

const OrderHistoryInput = props => {
  return <Input height="40px" {...props} />;
};
class OrderHistory extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    configs: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object]),
      })
    ),
    specifications: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    token: PropTypes.string,
    apiRequestError: PropTypes.string,
    order_history: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        order_date: PropTypes.string.isRequired,
      })
    ),
    export_order_history: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        order_date: PropTypes.string.isRequired,
      })
    ),
    orderPagination: PropTypes.objectOf(PropTypes.string),
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        value: PropTypes.string.isRequired,
      })
    ),
    grinders: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    packer_plants: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        est: PropTypes.string,
        packer_id: PropTypes.number,
      })
    ),
    input_products: PropTypes.arrayOf(
      PropTypes.shape({
        uid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        cl: PropTypes.string,
      })
    ),
    packageWeights: PropTypes.arrayOf(
      PropTypes.shape({
        weight: PropTypes.number.isRequired,
        unit: PropTypes.string.isRequired,
      })
    ),
    packers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    end_users: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        legal_name: PropTypes.string.isRequired,
        is_active: PropTypes.bool.isRequired,
      })
    ),
    users: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        token: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        email: PropTypes.string,
      })
    ),
    user: PropTypes.shape({
      id: PropTypes.number,
      token: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
    }).isRequired,
    poGridtoggleStatus: PropTypes.bool,
    orderStatusMap: PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      currentOrder: false,
      multifilters: CLEAN_FILTERS,
      useFirstPageOneOnNextSearch: false,
      isExporting: false,
      isPOGridExporting: false,
      showMoreFilters: false,
    };
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(clearOrders());
  }

  componentDidMount() {
    const { token, dispatch, configs, specifications } = this.props;
    dispatch(fetchEntitiesData());
    dispatch(fetchBuyerOrgUsers(token));
    dispatch(fetchPackageWeights());
    if (configs.length === 0) {
      dispatch(fetchConfigs(token));
    }
    if (specifications.length === 0) {
      dispatch(fetchSpecifications(token));
    }
    // Dispatch the fetchOrderStatusMap action
    dispatch(fetchOrderStatusMap());
    this.submitSearch();
  }

  componentDidUpdate(prevProps) {
    const { apiRequestError, poGridtoggleStatus } = this.props;

    // reset loading state when request fails
    if (apiRequestError !== prevProps.apiRequestError) {
      this.setState({
        loading: false,
      });
    }

    if (poGridtoggleStatus !== prevProps.poGridtoggleStatus) {
      this.resetSearch();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { order_history } = this.props;
    if (
      nextProps.order_history.length ||
      (nextProps.order_history.length === 0 && order_history !== nextProps.order_history)
    ) {
      this.setState({ loading: false });
    }
  }

  setLoading = () => {
    this.setState({ loading: true });
  };

  updateFilter = (filterKey, filterValue) => {
    this.setState(prevState => ({
      multifilters: {
        ...prevState.multifilters,
        [filterKey]: filterValue,
      },
      useFirstPageOneOnNextSearch: true,
    }));
  };

  handleDateRangeFilterChange = (filterConfig, instasearch) => {
    if (instasearch) {
      return this.instasearchOrders(filterConfig);
    }
    return this.setState(prevState => ({
      multifilters: {
        ...prevState.multifilters,
        ...filterConfig,
      },
    }));
  };

  handlePageChange = filterConfig => {
    this.instasearchOrders({
      ...this.state.multifilters,
      ...filterConfig,
    });

    return this.setState(prevState => ({
      multifilters: {
        ...prevState.multifilters,
        ...filterConfig,
      },
    }));
  };

  handleFilterChange = (key, value, instasearch) => {
    const arrayFilters = ['status', 'user_id'];
    const booleanFilters = ['exclude_delisted_packers', 'noEtd', 'partial_search'];

    if (instasearch) {
      return this.instasearchOrders({ [key]: value });
    }
    if (value && value !== 'null') {
      const currentValue = get(this.state, `multifilters.${key}`);

      // These two keys can have multiple values, and are array types
      if (arrayFilters.includes(key)) {
        let updatedArray = [];
        // If status already has existing value, then remove it
        if (currentValue.includes(value)) {
          updatedArray = currentValue.filter(item => item !== value);
        } else {
          // Otherwise add it to an array
          updatedArray = [...currentValue, value];
        }
        return this.updateFilter(key, updatedArray);
      }
      // Otherwise, just add straight to object for checkbox fields in 'Other' category
      if (booleanFilters.includes(key)) {
        return this.updateFilter(key, !currentValue);
      }
      return this.updateFilter(key, value);
    }
    // If is null or undefined, then change value to blank
    return this.updateFilter(key, '');
  };

  instasearchOrders = searchConfig => {
    // Clears everything and then sets a simple one-thing search
    const updatedMultiFilters = {
      ...CLEAN_FILTERS,
      ...searchConfig,
    };

    this.setState(
      {
        multifilters: updatedMultiFilters,
        loading: true,
      },
      () => {
        this.props.dispatch(fetchOrderHistory(updatedMultiFilters, this.props.token));
      }
    );
  };

  onPackerStuffSelect = (fieldName, value) => {
    const { multifilters } = this.state;
    const fieldToClear = fieldName === 'packer_plant_ids' ? 'packer_ids' : 'packer_plant_ids';
    const newMultifilters = Object.assign({}, multifilters);
    newMultifilters[fieldName] = value;
    newMultifilters[fieldToClear] = null;

    this.setState({ multifilters: newMultifilters, useFirstPageOneOnNextSearch: true });
  };

  onBuyerSelect = value => {
    const { multifilters } = this.state;
    const newMultifilters = { ...multifilters, buyer_id: value || '' };
    this.setState({ multifilters: newMultifilters, useFirstPageOneOnNextSearch: true });
  };

  onEndUserSelect = value => {
    const { multifilters } = this.state;
    const newMultifilters = { ...multifilters, end_user_id: value || '' };
    this.setState({ multifilters: newMultifilters, useFirstPageOneOnNextSearch: true });
  };

  removeFilter = (key, value = null) => {
    const { multifilters } = this.state;
    const newMultifilters = { ...multifilters, [key]: value };
    this.setState({ multifilters: newMultifilters, useFirstPageOneOnNextSearch: true });
    setTimeout(this.submitSearch, 1);
  };

  getSelectedFilters = () => {
    const { multifilters } = this.state;
    const selectedFilters = [];
    const filterKeys = Object.keys(filtersSchema);

    filterKeys.forEach(key => {
      const value = multifilters[key];
      if (value && (Array.isArray(value) ? value.length : value)) {
        selectedFilters.push({ key, value, label: filtersSchema[key].label });
      }
    });
    return selectedFilters;
  };

  getLabelWithValueType = (key, value) => {
    let buyerUser;
    switch (key) {
      case 'buyer_id':
        buyerUser = this.props.users.find(user => user.id === value);
        return `${buyerUser?.first_name} ${buyerUser?.last_name}`;
      case 'end_user_id':
        return this.props.end_users.find(user => user.id === value)?.legal_name;
      case 'isColdStoreRequired':
        return value === 'true' ? 'Yes' : 'No';
      case 'grinder_uids':
        return this.props.grinders.find(grinder => grinder.uid === value)?.name;
      default:
        return value;
    }
  };

  submitSearch = e => {
    if (e) {
      e.preventDefault();
    }
    this.props.dispatch(fetchOrderHistory(this.prepareFilters(), this.props.token));
    this.setLoading();
  };

  prepareFilters = () => {
    const filters = Object.assign({}, this.state.multifilters, {
      ...(this.state.useFirstPageOneOnNextSearch && {
        page: 1,
      }),
    });
    return filters;
  };

  resetSearch = e => {
    if (e) {
      e.preventDefault();
    }

    const newState = JSON.parse(JSON.stringify(this.state));
    newState.multifilters = CLEAN_FILTERS;
    newState.useFirstPageOneOnNextSearch = false;
    this.setState(newState);
    // this.props.dispatch(resetPurchaseOrders());
    setTimeout(this.submitSearch, 1);
  };

  renderInput = (props, refName) => {
    return (
      <InputGroup endElement={<FaCalendar style={{ height: '100%' }} color="tertiary.900" />}>
        <input
          {...props}
          ref={el => {
            this[refName] = el;
          }}
        />
      </InputGroup>
    );
  };

  selectDate = (e, name, ref) => {
    // eslint-disable-next-line no-underscore-dangle
    this.handleFilterChange(name, e._isAMomentObject ? e.format('YYYY-MM-DD') : e);

    if (ref) {
      ref.focus();
    }
  };

  // eslint-disable-next-line class-methods-use-this
  getLineSpecifications = line => {
    return line.specifications;
  };

  exportPOGridOrders() {
    const { dispatch, token } = this.props;
    this.setState({
      isPOGridExporting: true,
    });
    wrapDispatch(dispatch, fetchOrderHistory(this.prepareFilters(), token, true)).then(() => {
      const {
        // order_history,
        packer_plants,
        packers,
        grinders,
        input_products,
        packageWeights,
        export_order_history: exportOrderHistory,
      } = this.props;
      if (!exportOrderHistory) {
        this.setState({
          isPOGridExporting: false,
        });
        return;
      }
      // const internalPOLabel = getInternalPoLabel(configs);
      const renderPackerName = order => {
        const packerPlant = packer_plants.find(pp => pp?.id === order.packer_plant_id);
        const packerId = packerPlant ? packerPlant.packer_id : null;
        const packer = packers.find(p => p?.id === packerId);
        return packer ? packer.name : '-';
      };
      /**
       * The formatDataMap function is used to retrieve and format specific data based on the provided dataMap.
       * This function takes in two arguments: `data` (an object containing the dataset) and `dataMap` (the key that indicates
       * which value from the `data` should be extracted and formatted).
       * Depending on the value of `dataMap`, the function applies different formatting rules to the extracted value.
       *
       * @param {Object} data - The dataset from which the value is extracted.
       * @param {string} dataMap - The key used to extract the value from the dataset.
       * @returns {string} - The formatted value based on the dataMap. If no specific formatting is applied, it returns the raw value.
       */
      const formatDataMap = (data, dataMap) => {
        // Extract the value from the data object using the provided dataMap key.
        const value = get(data, dataMap, 0);
        // Switch based on the dataMap key to determine how to format the extracted value.
        switch (dataMap) {
          // If dataMap is 'line.price_type', capitalize the first letter and lowercase the rest.
          case 'line.price_type':
            return value ? value.toUpperCase() : ''; // Return formatted value with all letter in uppercase
          // If dataMap is 'packer.name', call renderPackerName with the current row's data to get the packer's name.
          case 'packer.name':
            return renderPackerName(data.order);
          // For fields related to pricing, convert the value to a float and format it with 4 decimal places.
          case 'line.group_housed_premium_rate':
          case 'order.transport_cost_per_unit':
          case 'line.buy_price_per_unit':
          case 'line.sell_price_per_unit':
          case 'line.price_per_unit':
            return value ? parseFloat(value).toFixed(4) : '0.0000'; // Return formatted value with 4 decimal places
          // If dataMap is 'order.order_date', format the date to 'YYYY-MM-DD' based on the purchasing office's timezone.
          case 'order.order_date':
            const purchasingOffice = get(data, 'order.purchasing_office', 'Sydney');
            return value
              ? moment(value)
                .tz(purchasingOffice.toLowerCase() === 'sydney' ? 'Australia/Sydney' : 'America/Chicago')
                .format('YYYY-MM-DD')
              : '';
          default:
            return value;
        }
      };

      const worksheetColumnConfig = [
        { 'Delivery Date': 'order.delivery_date' },
        { Day: 'order_delivery_day' },
        { 'Grinder PO#': 'order.grinder_po_number' },
        { Grinder: 'grinder.name' },
        { Establishment: 'packer_plant.name' },
        { 'Establishment ID': 'packer_plant.est' },
        { Packer: 'packer.name' },
        { Product: 'line.input_product.name' },
        { 'Quantity Buy': 'line.buy_quantity' },
        { 'Sell Unit Price': 'line.sell_price_per_unit' },
        { 'Estimated Pack Date': 'estimated_production_date' },
        { 'Pack Date': 'production_date' },
        { 'Week Ending': 'week_ending_date' },
        { 'Order Type': 'line.price_type' },
        { 'Fob Price': 'line.price_per_unit' },
        { 'Group Housed Premium': 'line.group_housed_premium_rate' },
        { 'Freight Rate': 'order.transport_cost_per_unit' },
        { 'Packer delivered': 'line.buy_price_per_unit' },
        { 'Vendor SO#': 'order.packer_so_number' },
        { 'Order Type Notes': 'line.order_type_notes' },
        { 'Internal Comments': 'order.internal_comments' },
      ];
      generateAndDownloadExcel('Purchase Orders', [
        {
          worksheetName: 'Purchase Orders',
          columnConfigs: worksheetColumnConfig.reduce((agg, config) => {
            const configKeyPair = Object.entries(config)[0];
            const headerText = configKeyPair[0];
            const dataMap = configKeyPair[1];

            return [
              ...agg,
              {
                headerText,
                getter: data => {
                  return formatDataMap(data, dataMap); // Call formatDataMap with the current row's data and the specific dataMap.
                },
              },
            ];
          }, []),
          data: exportOrderHistory
            .map(order => {
              return order.lines.map(line => {
                return {
                  order,
                  line: {
                    ...line,
                    product: input_products.find(product => product.uid === line.input_product_uid),
                    description:
                      line.specifications.includes('fmg_angus') || line.specifications.includes('usda_angus')
                        ? 'Angus'
                        : 'Regular',
                    specification: this.getLineSpecifications(line),
                    temp: (() => {
                      if (line.frozen) return 'Frozen';
                      if (line.chilled) return 'Chilled';
                      return '';
                    })(),
                    status: line.active ? order.status : '',
                    remaining_weight: line.carton_count
                      ? convertCartonsToUnitOfMeasure(
                        line.carton_count,
                        get(order, 'sell_unit_of_measure.name'),
                        line.input_product_uid,
                        order.packer_plant_id,
                        order.purchasing_office,
                        packageWeights
                      )
                      : 0,
                  },
                  packer_plant: packer_plants.find(packer_plant => packer_plant.id === order.packer_plant_id),
                  grinder: grinders.find(grinder => grinder.uid === order.grinder_uid),
                  packer: renderPackerName(order),
                  dateType: {
                    type: (() => {
                      if (order.shipment) return 'Shipment';
                      if (order.arrival) return 'Arrival';
                      return '';
                    })(),
                    start_date: (() => {
                      if (order.shipment) return order.shipment_date_start;
                      if (order.arrival) return order.arrival_date_start;
                      return '';
                    })(),
                    end_date: (() => {
                      if (order.shipment) return order.shipment_date_end;
                      if (order.arrival) return order.arrival_date_end;
                      return '';
                    })(),
                  },
                  estimated_production_date:
                    Array.isArray(line?.estimated_production_details) && line.estimated_production_details.length > 0
                      ? line.estimated_production_details.map(detail => detail.production_date).join(', ')
                      : '',
                  production_date:
                    Array.isArray(line?.production_details) && line.production_details.length > 0
                      ? line.production_details.map(detail => detail.production_date).join(', ')
                      : '',
                  order_delivery_day: moment(order.delivery_date).format('dddd'),
                  coldstore_name: order?.coldstore_details?.cold_store?.company_name,
                  week_ending_date: moment(order.delivery_date).isoWeekday(7).format('YYYY-MM-DD'),
                };
              });
            })
            .flat(1),
        },
      ]);
      this.setState({
        isPOGridExporting: false,
      });
    });
  }

  exportOrders() {
    const { dispatch, token, configs } = this.props;

    this.setState({
      isExporting: true,
    });
    wrapDispatch(dispatch, fetchOrderHistory(this.prepareFilters(), token, true)).then(() => {
      const {
        // order_history,
        packer_plants,
        packers,
        grinders,
        input_products,
        packageWeights,
        export_order_history: exportOrderHistory,
      } = this.props;
      if (!exportOrderHistory) {
        this.setState({
          isExporting: false,
        });
        return;
      }
      const internalPOLabel = getInternalPoLabel(configs);
      const renderPackerName = order => {
        const packerPlant = packer_plants.find(pckrPlant => pckrPlant?.id === order.packer_plant_id);
        const packerId = packerPlant ? packerPlant.packer_id : null;
        const packer = packers.find(pckr => pckr?.id === packerId);
        return packer ? packer.name : '-';
      };
      const worksheetColumnConfig = [
        { 'Date Created': 'order.order_date' },
        { Grinder: 'grinder.name' },
        { 'Grinder PO#': 'order.grinder_po_number' },
        { [internalPOLabel]: 'order.internal_po_number' },
        { Establishment: 'packer_plant.name' },
        { 'Establishment ID': 'packer_plant.est' },
        { Packer: 'packer.name' },
        { 'Packer SO#': 'order.packer_so_number' },
        { Status: 'line.status' },
        { IsActive: 'line.active' },
        { 'Delivery Date': 'order.delivery_date' },
        { Temp: 'line.temp' },
        { Product: 'line.input_product.name' },
        { 'Product Description': 'line.input_product.description' },
        { CL: 'line.input_product.cl' },
        { Description: 'line.description' },
        { Specification: 'line.specification' },
        { 'Quantity Buy': 'line.buy_quantity' },
        { 'Buy UOM': 'order.buy_unit_of_measure.name' },
        { 'Buy Price Per Unit': 'line.buy_price_per_unit' },
        { 'Buy Currency': 'order.buy_currency' },
        { 'Quantity Sell': 'line.sell_quantity' },
        { 'Sell UOM': 'order.sell_unit_of_measure.name' },
        { 'COS Price Per Unit': 'line.cos_price_per_unit' },
        { 'Sell Price Per Unit': 'line.sell_price_per_unit' },
        { 'Sell Currency': 'order.sell_currency' },
        { 'Received Quantity': 'line.recv_quantity' },
        { 'Remaining Weight': 'line.remaining_weight' },
        { 'Internal Comments': 'order.internal_comments' },
        { 'External Comments': 'order.external_comments' },
        { Container: 'order.load_container_details' },
        { 'Buy Incoterms': 'order.buy_incoterms' },
        { 'Sell Incoterms': 'order.sell_incoterms' },
        { Halal: 'order.halal' },
        { MTC: 'order.mtc' },
        { 'Delivery Time': 'order.scheduled_delivery_time' },
        { 'Transport Cost': 'order.transport_cost' },
        { 'Date Type': 'dateType.type' },
        { 'Shipment/Arrival Start Date': 'dateType.start_date' },
        { 'Shipment/arrival End Date': 'dateType.end_date' },
        { 'Preferred ETA': 'order.preferred_eta' },
        { 'Expected Production Date': 'line.expected_production_date' },
        { 'Final Destination': 'order.final_destination' },
        { 'Vessel Name': 'order.voyage[0].vessel_name' },
        { 'Voyage Number': 'order.voyage[0].voyage_number' },
        { 'Shipping Line': 'order.voyage[0].shipping_line.name' },
        { 'Port of Loading': 'order.voyage[0].load_port.name' },
        { ETD: 'order.voyage[0].etd' },
        { 'Port of Discharge': 'order.voyage[0].discharge_port.name' },
        { ETA: 'order.voyage[0].eta' },
        { 'Tranship Port': 'order.voyage[0].tranship_port.name' },
        { 'Booking Reference #': 'order.bill_of_lading.booking_reference_number' },
        { EDN: 'order.bill_of_lading.export_declaration_number' },
        { 'Container #': 'order.bill_of_lading.container_number' },
        { 'Container Temp': 'order.bill_of_lading.container_temperature' },
        { 'Seal #': 'order.bill_of_lading.seal_number' },
        { 'Bill of Lading #': 'order.bill_of_lading.bol_number' },
        { 'Health Certificate #': 'order.export_details.health_certificate_number' },
        { ISF: 'order.export_details.isf' },
        { 'Permit #': 'order.export_details.permit_number' },
        { 'Container Depot': 'order.export_details.container_depot' },
        { 'Container Return': 'order.export_details.container_return' },
        { 'Container Gate in': 'order.export_details.container_gate_in_date' },
        { 'Coldstore Name': 'coldstore_name' },
        { 'Gross Shipped Weight': 'order.bill_of_lading.gross_shipped_weight' },
        { 'Customs Cleared Date': 'order.coldstore_details.customed_cleared_date' },
        { 'Inspection Date': 'order.coldstore_details.inspection_date' },
      ];
      generateAndDownloadExcel('Purchase Orders', [
        {
          worksheetName: 'Purchase Orders',
          columnConfigs: worksheetColumnConfig.reduce((agg, config) => {
            const configKeyPair = Object.entries(config)[0];
            const headerText = configKeyPair[0];
            const dataMap = configKeyPair[1];
            return [
              ...agg,
              {
                headerText,
                getter: data => {
                  if (dataMap === 'packer.name') {
                    return renderPackerName(data.order);
                  }
                  if (dataMap === 'order.order_date') {
                    const purchasingOffice = get(data, 'order.purchasing_office', 'Sydney');
                    const formatted = get(data, dataMap, null)
                      ? moment(get(data, dataMap))
                        .tz(purchasingOffice.toLowerCase() === 'sydney' ? 'Australia/Sydney' : 'America/Chicago')
                        .format('YYYY-MM-DD')
                      : '';
                    return formatted;
                  }
                  return get(data, dataMap, '');
                },
              },
            ];
          }, []),
          data: exportOrderHistory
            .map(order => {
              return order.lines.map(line => {
                return {
                  order,
                  line: {
                    ...line,
                    product: input_products.find(product => product.uid === line.input_product_uid),
                    description:
                      line.specifications.includes('fmg_angus') || line.specifications.includes('usda_angus')
                        ? 'Angus'
                        : 'Regular',
                    specification: this.getLineSpecifications(line),
                    temp: (() => {
                      if (line.frozen) return 'Frozen';
                      if (line.chilled) return 'Chilled';
                      return '';
                    })(),
                    status: line.active ? order.status : '',
                    remaining_weight: line.carton_count
                      ? convertCartonsToUnitOfMeasure(
                        line.carton_count,
                        get(order, 'sell_unit_of_measure.name'),
                        line.input_product_uid,
                        order.packer_plant_id,
                        order.purchasing_office,
                        packageWeights
                      )
                      : 0,
                  },
                  packer_plant: packer_plants.find(packer_plant => packer_plant.id === order.packer_plant_id),
                  grinder: grinders.find(grinder => grinder.uid === order.grinder_uid),
                  packer: renderPackerName(order),
                  dateType: {
                    type: (() => {
                      if (order.shipment) return 'Shipment';
                      if (order.arrival) return 'Arrival';
                      return '';
                    })(),
                    start_date: (() => {
                      if (order.shipment) return order.shipment_date_start;
                      if (order.arrival) return order.arrival_date_start;
                      return '';
                    })(),
                    end_date: (() => {
                      if (order.shipment) return order.shipment_date_end;
                      if (order.arrival) return order.arrival_date_end;
                      return '';
                    })(),
                  },
                  coldstore_name: order?.coldstore_details?.cold_store?.company_name,
                };
              });
            })
            .flat(1),
        },
      ]);
      this.setState({
        isExporting: false,
      });
    });
  }

  render() {
    const {
      orderStatusMap,
      packer_plants,
      input_products,
      grinders,
      packers,
      end_users,
      users,
      user,
      configs,
      order_history,
      poGridtoggleStatus,
    } = this.props;
    const { loading, currentOrder, isExporting, multifilters, showMoreFilters, isPOGridExporting } = this.state;
    const { tags, orderPagination, specifications } = this.props;

    if (currentOrder) {
      currentOrder.line_items.map(line_item => {
        let thisPacker = false;
        packer_plants.forEach(packer_plant => {
          if (packer_plant.id === line_item.packer_plant_id) {
            thisPacker = packer_plant;
          }
        });
        let thisInputProduct = false;
        input_products.forEach(input_product => {
          if (input_product.uid === line_item.input_product_uid) {
            thisInputProduct = input_product;
          }
        });
        return (
          <Table.Row key={`line-item-${line_item.uid}`} style={{ background: '#f3f4f8' }}>
            <Table.Cell />
            <Table.Cell>{thisPacker.name}</Table.Cell>
            <Table.Cell>{thisInputProduct.name}</Table.Cell>
            <Table.Cell>{commify(line_item.buy_quantity)}</Table.Cell>
            <Table.Cell />
            <Table.Cell>${line_item.ingredient_cost_per_unit}</Table.Cell>
            <Table.Cell>${line_item.transport_cost_per_unit}</Table.Cell>
            <Table.Cell>${line_item.all_in_cost_per_unit}</Table.Cell>
            <Table.Cell>${commify(line_item.all_in_cost)}</Table.Cell>
          </Table.Row>
        );
      });
    }

    const sortOptionsByLabel = (a, b) => {
      const x = a.label;
      const y = b.label;
      if (x < y) {
        return -1;
        // eslint-disable-next-line no-else-return
      } else if (x > y) {
        return 1;
      } else {
        return 0;
      }
    };
    const grinderOptions = (grinders || [])
      .map(item => ({ value: item.uid, label: item.name }))
      .sort(sortOptionsByLabel);

    const Pplants = packer_plants || [];
    const individualPackerOptions = (
      multifilters.packer_ids ? Pplants.filter(pp => multifilters.packer_ids === pp.packer_id) : Pplants
    )
      .map(item => ({ value: item.id, label: item.name }))
      .sort(sortOptionsByLabel);

    const packerOptions = (packers || []).map(item => ({ value: item.id, label: item.name })).sort(sortOptionsByLabel);

    const endUserOptions = (end_users || [])
      .filter(item => item.is_active)
      .map(item => ({ value: item.id, label: item.legal_name }))
      .sort(sortOptionsByLabel);

    const buyerOptions = constructBuyerOptions(buyersSelector(users), user).sort(sortOptionsByLabel);

    const labelOptions = tags && tags.map(l => ({ label: l.value, value: l.value })).sort(sortOptionsByLabel);
    const specificationOptions =
      specifications && specifications.map(l => ({ label: l.name, value: l.uid })).sort(sortOptionsByLabel);
    const startDateProps = {
      placeholder: 'From',
      className: 'form-control m-input',
    };
    const endDateProps = {
      placeholder: 'To',
      className: 'form-control m-input',
    };
    const internalPOLabel = getInternalPoLabel(configs);

    const filterOptions = createListCollection({
      items: [
        { label: internalPOLabel, value: 'internal_po_number' },
        { label: 'Grinder PO #', value: 'grinder_po_number' },
        { label: 'Packer SO #', value: 'packer_so_number' },
        { label: 'Packer Invoice #', value: 'packer_invoice_number' },
      ],
    });

    return (
      <form onSubmit={this.submitSearch}>
        <Box className="order-history" my="2em" mx="4em">
          <HStack gap="10px" justifyContent="space-between" mb={2}>
            <HStack>
              <RightAngleIcon />
              <Heading className="dashboard-section__heading" fontSize="15px">
                Orders
              </Heading>
            </HStack>
            <HStack className="filters">
              <Box>
                <div className="po-filter">
                  <SelectRoot
                    collection={filterOptions}
                    borderRadius="md"
                    size="sm"
                    width="147px"
                    value={[multifilters.po_lookup_field]}
                    onValueChange={e => {
                      this.handleFilterChange('po_lookup_field', e.value[0] || null);
                      this.handleFilterChange('internal_po_number', '');
                      this.handleFilterChange('grinder_po_number', '');
                      this.handleFilterChange('packer_so_number', '');
                      this.handleFilterChange('packer_invoice_number', '');
                    }}
                  >
                    <SelectTrigger>
                      <SelectValueText />
                    </SelectTrigger>
                    <SelectContent>
                      {filterOptions.items.map(opt => (
                        <SelectItem item={opt} key={opt.value}>
                          {opt.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </SelectRoot>
                  <InputGroup size="md" startElement={<FaSearch color="gray.300" />}>
                    <Input
                      name="po_number"
                      width="354px"
                      type="text"
                      borderRadius="md"
                      placeholder="Search"
                      value={multifilters[multifilters.po_lookup_field] || ''}
                      onChange={e => {
                        this.handleFilterChange(multifilters.po_lookup_field, get(e, 'target.value', null));
                      }}
                    />
                  </InputGroup>
                </div>
              </Box>
              {/* STATUS */}
              <Box position="relative">
                <MenuRoot closeOnSelect={false}>
                  <MenuTrigger
                    as={Button}
                    width="130px"
                    variant="outline"
                    colorScheme="gray.200"
                    color="gray.500"
                    borderColor="gray.200"
                    display="flex"
                    alignItems="center"
                    asChild
                  >
                    <Box display="flex" alignItems="center" justifyContent="space-between" w="100%">
                      <Box as="span" lineHeight="0">
                        Status
                      </Box>
                      <FaChevronDown style={{ width: '10px' }} />
                      {multifilters?.status?.length > 0 && (
                        <Box
                          as="span"
                          width="18px"
                          height="18px"
                          marginLeft="5px"
                          borderRadius="full"
                          bgColor="#3B44B0"
                          color="white"
                          fontSize="12px"
                          lineHeight="0"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          {multifilters?.status?.length}
                        </Box>
                      )}
                    </Box>
                  </MenuTrigger>
                  <MenuContent>
                    <VStack alignItems="flex-start" p={6} gap={4}>
                      {orderStatusMap &&
                        Object.keys(orderStatusMap)
                          .filter(
                            key => !['pending_coldstore_email', 'dispatched', 'invoiced', 'submitted'].includes(key)
                          )
                          .map(key => ({ value: key, label: orderStatusMap[key] }))
                          .sort(sortOptionsByLabel)
                          .map(({ value, label }) => (
                            <Checkbox
                              key={value}
                              value={value}
                              checked={multifilters.status.indexOf(value) > -1}
                              onChange={() => this.handleFilterChange('status', value)}
                            >
                              {label}
                            </Checkbox>
                          ))}
                    </VStack>
                  </MenuContent>
                </MenuRoot>
              </Box>
              {/* DATE */}
              <Box>
                <MenuRoot closeOnSelect={false}>
                  <MenuTrigger
                    as={Button}
                    variant="outline"
                    colorScheme="gray.200"
                    color="gray.500"
                    borderColor="gray.200"
                    width="130px"
                  >
                    <Box display="flex" alignItems="center" justifyContent="space-between" w="100%">
                      <Box as="span" lineHeight="0">
                        Date
                      </Box>
                      <FaChevronDown style={{ width: '10px' }} />
                      {(multifilters.ordered_start_date ||
                        multifilters.ordered_end_date ||
                        multifilters.delivery_start_date ||
                        multifilters.delivery_end_date ||
                        multifilters.etd_start_date ||
                        multifilters.etd_end_date ||
                        multifilters.eta_start_date ||
                        multifilters.eta_end_date) && (
                          <Box
                            as="span"
                            width="18px"
                            height="18px"
                            marginLeft="5px"
                            borderRadius="full"
                            bgColor="#3B44B0"
                            color="white"
                            fontSize="12px"
                            lineHeight="0"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            {
                              [
                                multifilters.ordered_start_date,
                                multifilters.ordered_end_date,
                                multifilters.delivery_start_date,
                                multifilters.delivery_end_date,
                                multifilters.etd_start_date,
                                multifilters.etd_end_date,
                                multifilters.eta_start_date,
                                multifilters.eta_end_date,
                              ].filter(Boolean).length
                            }
                          </Box>
                        )}
                    </Box>
                  </MenuTrigger>
                  <MenuContent>
                    <Stack p={3} gap={4}>
                      {/* DATE CREATED */}
                      <Field label="Date Created">
                        <HStack>
                          <Datetime
                            id="ordered_start_date"
                            inputProps={startDateProps}
                            closeOnSelect
                            value={multifilters.ordered_start_date}
                            renderInput={props => this.renderInput(props, 'orderedStartRef')}
                            onChange={e => this.selectDate(e, 'ordered_start_date', this.orderedStartRef)}
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                          />
                          <Datetime
                            id="ordered_end_date"
                            inputProps={endDateProps}
                            closeOnSelect
                            value={multifilters.ordered_end_date}
                            renderInput={props => this.renderInput(props, 'orderedEndRef')}
                            onChange={e => this.selectDate(e, 'ordered_end_date', this.orderedEndRef)}
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                          />
                        </HStack>
                      </Field>
                      {/* DATE DELIVERY */}
                      <Field label="Date Delivery">
                        <HStack>
                          <Datetime
                            id="delivery_start_date"
                            inputProps={startDateProps}
                            closeOnSelect
                            value={multifilters.delivery_start_date}
                            renderInput={props => this.renderInput(props, 'deliveryStartRef')}
                            onChange={e => this.selectDate(e, 'delivery_start_date', this.deliveryStartRef)}
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                          />
                          <Datetime
                            id="delivery_end_date"
                            inputProps={endDateProps}
                            closeOnSelect
                            value={multifilters.delivery_end_date}
                            renderInput={props => this.renderInput(props, 'deliveryEndRef')}
                            onChange={e => this.selectDate(e, 'delivery_end_date', this.deliveryEndRef)}
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                          />
                        </HStack>
                      </Field>
                      {/* ETD */}
                      <Field label="ETD">
                        <HStack>
                          <Datetime
                            id="etd_start_date"
                            inputProps={startDateProps}
                            closeOnSelect
                            value={multifilters.etd_start_date}
                            renderInput={props => this.renderInput(props, 'etd_start_date')}
                            onChange={e => this.selectDate(e, 'etd_start_date', this.etd_start_date)}
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                          />
                          <Datetime
                            id="etd_end_date"
                            inputProps={endDateProps}
                            closeMenuOnSelect
                            value={multifilters.etd_end_date}
                            renderInput={props => this.renderInput(props, 'etd_end_date')}
                            onChange={e => this.selectDate(e, 'etd_end_date', this.etd_end_date)}
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                          />
                        </HStack>
                      </Field>
                      {/* ETA */}
                      <Field label="ETA">
                        <HStack>
                          <Datetime
                            id="eta_start_date"
                            inputProps={startDateProps}
                            closeOnSelect
                            value={multifilters.eta_start_date}
                            renderInput={props => this.renderInput(props, 'eta_start_date')}
                            onChange={e => this.selectDate(e, 'eta_start_date', this.eta_start_date)}
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                          />
                          <Datetime
                            id="eta_end_date"
                            inputProps={endDateProps}
                            closeMenuOnSelect
                            value={multifilters.eta_end_date}
                            renderInput={props => this.renderInput(props, 'eta_end_date')}
                            onChange={e => this.selectDate(e, 'eta_end_date', this.eta_end_date)}
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                          />
                        </HStack>
                      </Field>
                    </Stack>
                  </MenuContent>
                </MenuRoot>
              </Box>
              {/* GRINDER */}
              <Box>
                <MenuRoot closeOnSelect>
                  <MenuTrigger
                    // isActive={isOpen}
                    as={Button}
                    variant="outline"
                    colorScheme="gray.200"
                    color="gray.500"
                    borderColor="gray.200"
                    width="130px"
                  >
                    <Box display="flex" alignItems="center" justifyContent="space-between" w="100%">
                      <Box as="span" lineHeight="0">
                        Grinder
                      </Box>
                      <FaChevronDown style={{ width: '10px' }} />
                      {multifilters.grinder_uids && (
                        <Box
                          as="span"
                          width="18px"
                          height="18px"
                          marginLeft="5px"
                          borderRadius="full"
                          bgColor="#3B44B0"
                          color="white"
                          fontSize="12px"
                          lineHeight="0"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          1
                        </Box>
                      )}
                    </Box>
                  </MenuTrigger>
                  <MenuContent width="361px" overflow="visible">
                    <Stack p={3} gap={4}>
                      {/* GRINDER */}
                      <Field label="Grinder">
                        <OrderHistorySelectInternalSelect
                          name="select-grinder"
                          defaultValue={multifilters.grinder_uids}
                          options={grinderOptions}
                          placeholder="Select"
                          onChange={e => {
                            const value = get(e, 'value', null);
                            this.handleFilterChange('grinder_uids', value);
                          }}
                        />
                      </Field>
                    </Stack>
                  </MenuContent>
                </MenuRoot>
              </Box>
              {/* PACKER */}
              <Box>
                <MenuRoot closeOnSelect={false}>
                  <MenuTrigger
                    as={Button}
                    variant="outline"
                    colorScheme="gray.200"
                    color="gray.500"
                    borderColor="gray.200"
                    width="130px"
                  >
                    <Box display="flex" alignItems="center" justifyContent="space-between" w="100%">
                      <Box as="span" lineHeight="0">
                        Packer
                      </Box>
                      <FaChevronDown style={{ width: '10px' }} />
                      {(multifilters.packer_ids ||
                        multifilters.packer_plant_ids ||
                        multifilters.packer_so_number ||
                        multifilters.packer_invoice_number) && (
                          <Box
                            as="span"
                            width="18px"
                            height="18px"
                            marginLeft="5px"
                            borderRadius="full"
                            bgColor="#3B44B0"
                            color="white"
                            fontSize="12px"
                            lineHeight="0"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            {
                              [
                                multifilters.packer_ids,
                                multifilters.packer_plant_ids,
                                multifilters.packer_so_number,
                                multifilters.packer_invoice_number,
                              ].filter(Boolean).length
                            }
                          </Box>
                        )}
                    </Box>
                  </MenuTrigger>
                  <MenuContent width="361px" overflow="visible">
                    <Stack p={3} gap={4}>
                      {/* PACKER */}
                      <Field label="Packer">
                        <OrderHistorySelectInternalSelect
                          name="select-establishment-group"
                          defaultValue={multifilters.packer_ids}
                          options={packerOptions}
                          onChange={e => {
                            this.onPackerStuffSelect('packer_ids', e ? e.value : null);
                          }}
                          placeholder="Select"
                        />
                      </Field>
                      {/* ESTABLISHMENT */}
                      <Field label="Establishment">
                        <OrderHistorySelectInternalSelect
                          name="select-packer"
                          defaultValue={multifilters.packer_plant_ids}
                          options={individualPackerOptions}
                          onChange={e => {
                            if (e) {
                              this.onPackerStuffSelect('packer_plant_ids', e.value);
                            } else {
                              this.onPackerStuffSelect('packer_plant_ids', null);
                            }
                          }}
                          placeholder="Select"
                        />
                      </Field>
                      {/* PACKER SO # */}
                      <Field label="Packer SO #">
                        <OrderHistoryInput
                          type="text"
                          value={multifilters.packer_so_number}
                          onChange={e => this.handleFilterChange('packer_so_number', get(e, 'target.value', null))}
                          placeholder="Packer SO #"
                        />
                      </Field>
                      {/* PACKER INVOICE # */}
                      <Field label="Packer Invoice #">
                        <OrderHistoryInput
                          type="text"
                          value={multifilters.packer_invoice_number}
                          onChange={e => this.handleFilterChange('packer_invoice_number', get(e, 'target.value', null))}
                          placeholder="Packer Invoice #"
                        />
                      </Field>
                    </Stack>
                  </MenuContent>
                </MenuRoot>
              </Box>
              {/* LOGISTICS */}
              <Box>
                <MenuRoot closeOnSelect={false}>
                  <MenuTrigger
                    as={Button}
                    variant="outline"
                    colorScheme="gray.200"
                    color="gray.500"
                    borderColor="gray.200"
                    width="130px"
                  >
                    <Box display="flex" alignItems="center" justifyContent="space-between" w="100%">
                      <Box as="span" lineHeight="0">
                        Logistics
                      </Box>
                      {(multifilters.bol_number ||
                        multifilters.container_number ||
                        multifilters.booking_reference_number ||
                        multifilters.shipmentPortMark ||
                        multifilters.us_customs_entry_number ||
                        multifilters.orderShipmentTypes?.length > 0 ||
                        multifilters.missing_doc_type) && (
                          <Box
                            as="span"
                            width="18px"
                            height="18px"
                            marginLeft="5px"
                            borderRadius="full"
                            bgColor="#3B44B0"
                            color="white"
                            fontSize="12px"
                            lineHeight="0"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            {[
                              multifilters.bol_number,
                              multifilters.container_number,
                              multifilters.booking_reference_number,
                              multifilters.shipmentPortMark,
                              multifilters.us_customs_entry_number,
                              multifilters.missing_doc_type,
                            ].filter(Boolean).length + multifilters.orderShipmentTypes?.length}
                          </Box>
                        )}
                    </Box>
                    <FaChevronDown />
                  </MenuTrigger>
                  <MenuContent width="361px" overflow="visible">
                    <Stack p={3} gap={4}>
                      {/* BOL # */}
                      <Field label="BOL #">
                        <OrderHistoryInput
                          type="text"
                          name="bol_number"
                          value={multifilters.bol_number}
                          onChange={e => this.handleFilterChange('bol_number', get(e, 'target.value', null))}
                          placeholder="BOL #"
                        />
                      </Field>
                      {/* CONTAINER NUMBER */}
                      <Field label="Container Number">
                        <OrderHistoryInput
                          type="text"
                          name="container_number"
                          value={multifilters.container_number}
                          onChange={e => this.handleFilterChange('container_number', get(e, 'target.value', null))}
                          placeholder="Container Number"
                        />
                      </Field>
                      {/* BOOKING REFERENCE # */}
                      <Field label="Booking Reference #">
                        <OrderHistoryInput
                          type="text"
                          name="booking-reference-number"
                          value={multifilters.booking_reference_number}
                          onChange={e =>
                            this.handleFilterChange('booking_reference_number', get(e, 'target.value', null))
                          }
                          placeholder="Booking Reference #"
                        />
                      </Field>
                      {/* SHIPMENT PORT MARK */}
                      <Field label="Shipment Port Mark">
                        <OrderHistoryInput
                          type="text"
                          name="shipment-port"
                          value={multifilters.shipmentPortMark}
                          onChange={e => this.handleFilterChange('shipmentPortMark', get(e, 'target.value', null))}
                          placeholder="Shipment Port Mark"
                        />
                      </Field>
                      {/* US CUSTOMS ENTRY NUMBER */}
                      <Field label="US Customs Entry Number">
                        <OrderHistoryInput
                          type="text"
                          name="us-customs-entry-number"
                          value={multifilters.us_customs_entry_number}
                          onChange={e =>
                            this.handleFilterChange('us_customs_entry_number', get(e, 'target.value', null))
                          }
                          placeholder="US Customs Entry Number"
                        />
                      </Field>
                      {/* ORDER SHIPMENT TYPE */}
                      <Field label="Order Shipment Type">
                        <OrderHistorySelectInternalSelect
                          isMulti
                          isSearchable
                          closeMenuOnSelect
                          removeSelected
                          name="select-order-shipment"
                          defaultValue={multifilters.orderShipmentTypes}
                          options={Constants.ORDER_SHIPMENT_TYPE}
                          placeholder="Select"
                          onChange={e => this.handleFilterChange('orderShipmentTypes', e)}
                        />
                      </Field>
                      {/* MISSING DOCUMENT TYPE */}
                      <Field label="Missing Document Type">
                        <OrderHistorySelectInternalSelect
                          isSearchable
                          closeMenuOnSelect
                          removeSelected
                          name="select-missing-doc-type"
                          defaultValue={multifilters.missing_doc_type}
                          options={Constants.ORDER_MISSING_DOC_TYPE_OPTIONS}
                          placeholder="Select"
                          onChange={e => {
                            this.handleFilterChange('missing_doc_type', get(e, 'value', null));
                          }}
                        />
                      </Field>
                    </Stack>
                  </MenuContent>
                </MenuRoot>
              </Box>
              {/* OTHER */}
              <Box>
                <MenuRoot closeOnSelect={false}>
                  <MenuTrigger
                    as={Button}
                    width="130px"
                    variant="outline"
                    colorScheme="gray.200"
                    color="gray.500"
                    borderColor="gray.200"
                  >
                    <Box display="flex" alignItems="center" justifyContent="space-between" w="100%">
                      <Box as="span" lineHeight="0">
                        Other
                      </Box>
                      <FaChevronDown style={{ width: '10px' }} />
                      {(multifilters.product_types?.length > 0 ||
                        multifilters.specifications?.length > 0 ||
                        multifilters.tags?.length > 0 ||
                        multifilters.buyer_id ||
                        multifilters.end_user_id ||
                        multifilters.isColdStoreRequired) && (
                          <Box
                            as="span"
                            width="18px"
                            height="18px"
                            marginLeft="5px"
                            borderRadius="full"
                            bgColor="#3B44B0"
                            color="white"
                            fontSize="12px"
                            lineHeight="0"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            {[multifilters.buyer_id, multifilters.end_user_id, multifilters.isColdStoreRequired].filter(
                              Boolean
                            ).length +
                              multifilters.product_types?.length +
                              multifilters.specifications?.length +
                              multifilters.tags?.length}
                          </Box>
                        )}
                    </Box>
                  </MenuTrigger>
                  <MenuContent width="361px" overflow="visible">
                    <Stack p={3} gap={4}>
                      {/* PRODUCT TYPES */}
                      <Field label="Product Types">
                        <OrderHistorySelectInternalSelect
                          isMulti
                          isSearchable
                          closeMenuOnSelect
                          removeSelected
                          name="select-product-types"
                          defaultValue={multifilters.product_types}
                          options={Constants.PRODUCT_TYPES}
                          placeholder="Select"
                          onChange={e => this.handleFilterChange('product_types', e)}
                        />
                      </Field>
                      {/* SPECIFICATIONS */}
                      <Field label="Specifications">
                        <OrderHistorySelectInternalSelect
                          isMulti
                          isSearchable
                          closeOnSelect
                          removeSelected
                          name="select-specifications"
                          defaultValue={multifilters.specifications}
                          options={specificationOptions}
                          onChange={e => this.handleFilterChange('specifications', e)}
                          placeholder="Select"
                        />
                      </Field>
                      {/* TAGS */}
                      <Field label="Tags">
                        <OrderHistorySelectInternalSelect
                          isMulti
                          isSearchable
                          closeOnSelect
                          removeSelected
                          name="select-tags"
                          defaultValue={multifilters.tags}
                          options={labelOptions}
                          onChange={e => this.handleFilterChange('tags', e)}
                          placeholder="Select"
                        />
                      </Field>
                      {/* BUYER */}
                      <Field label="Buyer">
                        <OrderHistorySelectInternalSelect
                          name="select-buyer"
                          defaultValue={multifilters.buyer_id}
                          options={buyerOptions}
                          onChange={e => this.onBuyerSelect(e ? e.value : null)}
                          placeholder="Select"
                        />
                      </Field>
                      {/* END USER */}
                      <Field label="End User">
                        <OrderHistorySelectInternalSelect
                          isSearchable
                          name="select-end-user"
                          defaultValue={multifilters.end_user_id}
                          options={endUserOptions}
                          placeholder="Select"
                          onChange={e => {
                            this.onEndUserSelect(get(e, 'value', null));
                          }}
                        />
                      </Field>
                      <Field label="Coldstore Required">
                        <OrderHistorySelectInternalSelect
                          isSearchable
                          closeMenuOnSelect
                          removeSelected
                          name="select-coldstore-required"
                          defaultValue={multifilters.isColdStoreRequired}
                          options={Constants.TRUE_FALSE_OPTIONS}
                          placeholder="Select"
                          onChange={e => {
                            this.handleFilterChange('isColdStoreRequired', get(e, 'value', null));
                          }}
                        />
                      </Field>
                    </Stack>
                  </MenuContent>
                </MenuRoot>
              </Box>
              {/* RESET */}
              <Button variant="ghost" width="73px" height="43px" onClick={this.resetSearch}>
                <Text as="p" fontSize="16px">
                  Reset
                </Text>
              </Button>
              {/* FILTER */}
              <Button colorScheme="blue" width="140px" type="submit">
                Filter
              </Button>
            </HStack>
          </HStack>

          {this.getSelectedFilters().length > 0 && (
            <Box my={6} display="flex" justifyContent="flex-end">
              <Text as="p" fontSize="15px" fontWeight="bold" marginRight="10px" padding="5px 0" flexShrink="0">
                <Text as="b" color="#292F71">
                  Filters applied:
                </Text>
              </Text>

              <Box
                display="flex"
                gap="10px"
                marginRight="10px"
                flexWrap="wrap"
                height={showMoreFilters ? 'auto' : '32px'}
                overflow={showMoreFilters ? 'auto' : 'hidden'}
              >
                {this.getSelectedFilters().map((data, index) =>
                  Array.isArray(data.value) ? (
                    data.value.map((value, i) => (
                      <Box
                        key={i}
                        display="flex"
                        alignItems="center"
                        padding="5px 10px"
                        bgColor="#EBF0FF"
                        color="#292F71"
                        fontSize="14px"
                        borderRadius="6px"
                        marginRight="10px"
                      >
                        <Text as="b" fontSize="15px" fontWeight="bold" marginRight="5px">
                          {data.label}:
                        </Text>
                        <Text as="span" fontSize="15px" marginRight="10px" fontWeight="normal">
                          {data.label === 'Status' ? orderStatusMap[value] : value?.label || value}
                        </Text>
                        <button
                          type="button"
                          onClick={() =>
                            this.removeFilter(
                              data.key,
                              multifilters[data.key].filter(v => v !== value)
                            )
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                          >
                            <path
                              d="M5 3.88906L8.88906 0L10 1.11094L6.11094 5L10 8.88906L8.88906 10L5 6.11094L1.11094 10L0 8.88906L3.88906 5L0 1.11094L1.11094 0L5 3.88906Z"
                              fill="#1A202C"
                            />
                          </svg>
                        </button>
                      </Box>
                    ))
                  ) : (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      padding="5px 10px"
                      bgColor="#EBF0FF"
                      color="#292F71"
                      fontSize="14px"
                      borderRadius="6px"
                      marginRight="10px"
                    >
                      <Text as="b" fontSize="15px" fontWeight="bold" marginRight="5px">
                        {data.label}:
                      </Text>
                      <Text as="span" fontSize="15px" marginRight="10px" fontWeight="normal">
                        {this.getLabelWithValueType(data.key, data.value)}
                      </Text>
                      <button type="button" onClick={() => this.removeFilter(data.key, null)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                          <path
                            d="M5 3.88906L8.88906 0L10 1.11094L6.11094 5L10 8.88906L8.88906 10L5 6.11094L1.11094 10L0 8.88906L3.88906 5L0 1.11094L1.11094 0L5 3.88906Z"
                            fill="#1A202C"
                          />
                        </svg>
                      </button>
                    </Box>
                  )
                )}
              </Box>

              <button
                type="button"
                style={{
                  flexShrink: '0',
                  padding: '2px 8px',
                  borderRadius: '6px',
                  marginRight: '10px',
                  marginTop: '3px',
                  alignSelf: 'start',
                }}
              >
                <Text
                  display="flex"
                  alignItems="center"
                  as="p"
                  fontSize="15px"
                  fontWeight="bold"
                  whiteSpace="nowrap"
                  color="#3A44B0"
                  onClick={() => this.setState({ showMoreFilters: !showMoreFilters })}
                >
                  <Text as="span" marginRight="5px">
                    View {showMoreFilters ? 'Less' : 'More'}
                  </Text>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    style={{ rotate: showMoreFilters ? '180deg' : '0deg' }}
                  >
                    <path
                      d="M2 2L6 6L10 2"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="square"
                    />
                  </svg>
                </Text>
              </button>

              <button
                type="button"
                style={{
                  flexShrink: '0',
                  padding: '2px 8px',
                  backgroundColor: '#FFF9F5',
                  marginTop: '3px',
                  borderRadius: '6px',
                  alignSelf: 'start',
                }}
              >
                <Text
                  display="flex"
                  alignItems="center"
                  as="p"
                  fontSize="15px"
                  fontWeight="bold"
                  whiteSpace="nowrap"
                  color="#B03A3A"
                  onClick={this.resetSearch}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                    <path
                      d="M5 3.88906L8.88906 0L10 1.11094L6.11094 5L10 8.88906L8.88906 10L5 6.11094L1.11094 10L0 8.88906L3.88906 5L0 1.11094L1.11094 0L5 3.88906Z"
                      fill="currentColor"
                    />
                  </svg>
                  <Text as="span" marginLeft="5px">
                    Clear Filters
                  </Text>
                </Text>
              </button>
            </Box>
          )}

          <div className="result-container">
            {loading && (
              <Flex justifyContent="center" mt={4}>
                <Image src="img/loader.gif" alt="loader" />
              </Flex>
            )}

            {!loading && order_history.length > 0 ? (
              <Box width="100%" overflowX="auto">
                {!poGridtoggleStatus && (
                  <OrderTable
                    date_select={false}
                    setLoading={this.setLoading}
                    current_orders={this.props.order_history}
                    orderStatusMap={orderStatusMap}
                    {...this.props}
                  />
                )}
                {/* PO GRID TABLE */}
                {poGridtoggleStatus && this.getSelectedFilters().length > 0 && (
                  <GridOrderTable
                    date_select={false}
                    setLoading={this.setLoading}
                    current_orders={this.props.order_history}
                    orderStatusMap={orderStatusMap}
                    {...this.props}
                  />
                )}
                {poGridtoggleStatus && this.getSelectedFilters().length == 0 && (
                  <div style={{ textAlign: 'center', fontWeight: 'bold', marginTop: '20px' }}>No Record Found</div>
                )}
                <HStack pt="2em">
                  {/* EXPORT PO GRID ORDERS */}
                  {poGridtoggleStatus && this.getSelectedFilters().length > 0 && (
                    <>
                      <Button
                        width="207px"
                        height="43px"
                        disabled={this.props.order_history.length < 1}
                        onClick={this.exportPOGridOrders.bind(this)}
                        loading={isPOGridExporting}
                        loadingText="Exporting..."
                      >
                        <Text as="p" fontSize="md">
                          Export PO Grid Orders
                        </Text>
                        <CiExport size={20} />
                      </Button>
                      <PaginationBar
                        currentPage={
                          orderPagination.has_next ? orderPagination?.next_page - 1 || 1 : orderPagination?.pages
                        }
                        pages={orderPagination?.pages || 10}
                        onPageChange={page => {
                          this.handlePageChange({
                            page,
                          });
                        }}
                        justifyContent="flex-end"
                        marginY={6}
                        paddingX={5}
                      />
                    </>
                  )}

                  {/* EXPORT PO ORDER AND PAGINATION */}
                  {!poGridtoggleStatus && (
                    <>
                      <Button
                        width="207px"
                        height="43px"
                        disabled={this.props.order_history.length < 1}
                        onClick={this.exportOrders.bind(this)}
                        loading={isExporting}
                        loadingText="Exporting..."
                      >
                        <Text as="p" fontSize="md">
                          Export Listed Orders
                        </Text>
                        <CiExport size={20} />
                      </Button>
                      <PaginationBar
                        currentPage={
                          orderPagination.has_next ? orderPagination?.next_page - 1 || 1 : orderPagination?.pages
                        }
                        pages={orderPagination?.pages || 10}
                        onPageChange={page => {
                          this.handlePageChange({
                            page,
                          });
                        }}
                        justifyContent="flex-end"
                        marginY={6}
                        paddingX={5}
                      />
                    </>
                  )}
                </HStack>
              </Box>
            ) : (
              <div>
                <b>Enter your search filters and click the &quot;Filter&quot; button to get results.</b>
              </div>
            )}
          </div>
        </Box>
      </form>
    );
  }
}
const mapStateToProps = state => ({
  orderStatusMap: selectOrderStatusMap(state),
});

const mapDispatchToProps = dispatch => ({
  fetchOrderStatusMap: () => dispatch(fetchOrderStatusMap()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);
