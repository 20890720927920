import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
// import { CFSwitch } from '../../../basic/Toggle/Toggle';

import './DateSection.scss';
import { Box, HStack } from '@chakra-ui/react';
import CustomFormControl from '../../../core/CustomFormControl';
import POFormFieldLabel from '../edit-form/POFormFieldLabel';
import DateTimeWithTrappedFocus from '../../../core/DateTimeWithTrappedFocus';
import { changeableKeyCreator } from '../../../../utils';
import { Checkbox } from '@/components/ui/checkbox';
import { Switch } from '@/components/ui/switch';

const propTypes = {
  arrivalDateEnd: PropTypes.string,
  arrivalDateStart: PropTypes.string,
  isInEditMode: PropTypes.bool.isRequired,
  isShipmentDateRequiredPrompt: PropTypes.bool,
  onChangeFieldValue: PropTypes.func,
  onDateChange: PropTypes.func.isRequired,
  onToggleChange: PropTypes.func.isRequired,
  purchaseOrderIndex: PropTypes.number,
  requiredFields: PropTypes.instanceOf(Object).isRequired,
  requiredFieldsLabels: PropTypes.instanceOf(Object).isRequired,
  shipmentDateEnd: PropTypes.string,
  shipmentDateStart: PropTypes.string,
  showShipment: PropTypes.bool.isRequired,
};

const DateSection = ({
  arrivalDateStart,
  arrivalDateEnd,
  shipmentDateStart,
  shipmentDateEnd,
  showShipment,
  onToggleChange,
  onDateChange,
  requiredFields,
  isInEditMode,
  purchaseOrderIndex,
  isShipmentDateRequiredPrompt,
  onChangeFieldValue,
  requiredFieldsLabels,
}) => {
  const fieldName = showShipment ? 'Shipment' : 'Arrival';
  const keyStartName = showShipment ? 'shipmentDateStart' : 'arrivalDateStart';
  const keyEndName = showShipment ? 'shipmentDateEnd' : 'arrivalDateEnd';
  const valueStart = showShipment ? shipmentDateStart : arrivalDateStart;
  const valueEnd = showShipment ? shipmentDateEnd : arrivalDateEnd;
  let keyStartError = '';
  let keyEndError = '';

  let labelKeyStartError = '';
  let labelKeyEndError = '';
  // TODO have one format
  // pos is used in create, form for edit

  // shipment fields are not required when prompt is on
  if (!isShipmentDateRequiredPrompt || isShipmentDateRequiredPrompt == null) {
    if (purchaseOrderIndex) {
      keyStartError = get(requiredFields, `pos.${purchaseOrderIndex}.errors`, []).includes(keyStartName);
      keyEndError = get(requiredFields, `pos.${purchaseOrderIndex}.errors`, []).includes(keyEndName);
      labelKeyStartError = get(requiredFieldsLabels, `pos.${purchaseOrderIndex}.errors`, []).includes(keyStartName);
      labelKeyEndError = get(requiredFieldsLabels, `pos.${purchaseOrderIndex}.errors`, []).includes(keyEndName);
    } else {
      keyStartError = get(requiredFields, `form`, []).includes(keyStartName);
      keyEndError = get(requiredFields, `form`, []).includes(keyEndName);
      labelKeyStartError = get(requiredFieldsLabels, `form`, []).includes(keyStartName);
      labelKeyEndError = get(requiredFieldsLabels, `form`, []).includes(keyEndName);
    }
  }

  return (
    <>
      <HStack width="100%" alignItems="space-between" gap="25px">
        <Box width="50%">
          <CustomFormControl
            key="CustomFormControlDateType"
            changeableKey={changeableKeyCreator([isInEditMode, isShipmentDateRequiredPrompt, showShipment])}
            label="Date Type"
          >
            <Switch
              name="dateType"
              id="dateType"
              size="lg"
              colorScheme="actionPrimary"
              disabled={!isInEditMode}
              checked={!showShipment}
              onChange={e => onToggleChange(e.target.checked ? 'arrival' : 'shipment')}
            >
              {showShipment ? 'Shipment' : 'Arrival'}
            </Switch>
          </CustomFormControl>
        </Box>
        <Box width="50%">
          <CustomFormControl
            key="CustomFormControlPrompt"
            changeableKey={changeableKeyCreator([isInEditMode, isShipmentDateRequiredPrompt, showShipment])}
          >
            <POFormFieldLabel
              label="Prompt"
              htmlFor="prompt"
              tooltip="Indicates if prompt handling or processing is available."
            />
            <Checkbox
              name="prompt"
              id="prompt"
              colorScheme="actionSecondary"
              disabled={!isInEditMode}
              checked={isShipmentDateRequiredPrompt || false}
              onChange={e => onChangeFieldValue('isShipmentDateRequiredPrompt', { value: e.target.checked })}
            />
          </CustomFormControl>
        </Box>
      </HStack>

      <CustomFormControl
        key="CustomFormControlDate"
        changeableKey={changeableKeyCreator([
          isInEditMode,
          arrivalDateStart,
          arrivalDateEnd,
          shipmentDateStart,
          shipmentDateEnd,
          isShipmentDateRequiredPrompt,
          showShipment,
          requiredFields,
        ])}
      >
        <POFormFieldLabel
          label={`${fieldName} Start Date`}
          tooltip={`Select the start date of the ${fieldName} period.`}
          htmlFor="start_date"
          fieldName="shipmentDateStart"
          isRequired={labelKeyStartError || labelKeyEndError}
        />
        <HStack>
          <DateTimeWithTrappedFocus
            id="start-date"
            disabled={!isInEditMode}
            inputProps={{
              className: 'form-control m-input',
              disabled: !isInEditMode,
              placeholder: 'Start',
            }}
            className={keyStartError ? 'error' : ''}
            closeOnSelect
            value={valueStart}
            onChange={e =>
              // eslint-disable-next-line no-underscore-dangle
              onDateChange(`${fieldName} Start Date`, keyStartName, e._isAMomentObject ? e.format('YYYY-MM-DD') : null)
            }
            timeFormat={false}
            dateFormat="YYYY-MM-DD"
          />
          <DateTimeWithTrappedFocus
            id="delivery-date"
            inputProps={{
              className: 'form-control m-input',
              disabled: !isInEditMode,
              placeholder: 'End',
            }}
            closeOnSelect
            className={keyEndError ? 'error' : ''}
            value={valueEnd}
            onChange={e =>
              // eslint-disable-next-line no-underscore-dangle
              onDateChange(`${fieldName} End Date`, keyEndName, e._isAMomentObject ? e.format('YYYY-MM-DD') : null)
            }
            timeFormat={false}
            dateFormat="YYYY-MM-DD"
          />
        </HStack>
      </CustomFormControl>
    </>
  );
};

DateSection.propTypes = propTypes;

export default DateSection;
